import React from "react";
import { graphql } from "gatsby";
import { useFeatureMessageQuery } from "@querys/useFeatureMessageQuery";
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import FeatureMessage from "@components/nav/featureMessage";

import FilteredProjectsBlock from "@components/filteredProjectsBlock";
import TestimonialBlock from "../components/pageModules/modules/testimonialBlock";

const ProjectsTemplate = ({ data: { page } }) => {
  const { pageModules, seo, pageColour, testimonials } = page || {};
  const { nodes } = useFeatureMessageQuery();

  const bottomFeaturedMessage = nodes[0];

  return (
    <Layout pageColour={pageColour}>
      <PageMeta {...seo} />

      {pageModules && <ModuleZone {...pageModules} />}
      <FilteredProjectsBlock />
      {testimonials && (
        <div className="pt-space-m pb-space-l">
          <TestimonialBlock
            testimonials={testimonials}
            bgColor={"bg-light-grey-alt"}
          />
        </div>
      )}
      {bottomFeaturedMessage && <FeatureMessage {...bottomFeaturedMessage} />}
    </Layout>
  );
};

export default ProjectsTemplate;

export const projectsQuery = graphql`
  query StandardProjectsQuery {
    page: sanityProjects {
      seo {
        ...SEO
      }
      pageColour {
        title
        value
      }
      pageModules: modules {
        ...PageModules
      }
      title
      testimonials {
        author
        quote
        place
        isVideo
        video {
          asset {
            url
          }
        }
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;
