import React, { useEffect, useState } from "react";
import { useCaseStudyQuery } from "@querys/useCaseStudyQuery";
import { useTagsQuery } from "@querys/useTagsQuery";
import { Link } from "gatsby";
import { motion, AnimateSharedLayout } from "framer-motion";
import cx from "classnames";
import Next from "@svg/next.svg";
import Arrow from "@svg/arrow.svg";
import { useLocation } from "@reach/router";

const variants = {
  nonActive: {
    width: "80%",
    height: "40%",
    top: "10%",
    left: "10%",
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
  active: {
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const variants2 = {
  nonActive: {
    opacity: 1,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
  active: {
    opacity: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const FilteredProjectsBlock = () => {
  const location = useLocation();
  const { nodes } = useCaseStudyQuery();
  const { tags } = useTagsQuery();

  const [activeIndex, setActiveIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [activeTag, setActiveTag] = useState("Commercial");

  const tagFilter = nodes.filter((node) => node?.tag?.title === activeTag);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tag = searchParams.get("tag");
    const tagExists = tags.some((tagObj) => tagObj.title === tag);

    if (tag && tagExists) {
      setActiveTag(tag);
    } else {
      setActiveTag("Commercial");
    }
  }, [location.search]);

  const handleFilterClick = (tagTitle) => {
    setActiveTag(tagTitle);
    const url = new URL(window.location);
    url.searchParams.set("tag", tagTitle);
    window.history.pushState({}, "", url);
  };

  return (
    <AnimateSharedLayout>
      <div className="my-36 flex flex-col">
        <div className="self-center gap-2 lg:gap-0 lg:space-x-10 grid grid-cols-2 lg:flex my-4 lg:my-20 ">
          {/* Remove View All filter until further notice */}
          {/* <div
            className={cx("flex", {
              "btn-active ": activeTag === "all",
              "btn-standard": activeTag !== "all",
            })}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => setActiveTag("all")}
            role="button"
          >
            {activeTag === "all" || isHover ? (
              <Arrow className="w-6 mr-3" />
            ) : (
              <Next className="w-6 mr-3" />
            )}
            <p className="self-center">View All</p>
          </div> */}

          {tags.map(
            (tag, i) =>
              !tag.isHidden && (
                <div
                  key={`tag-${i}`}
                  value={tag.title}
                  className={cx("flex", {
                    "btn-active ": activeTag === tag.title,
                    "btn-standard": activeTag !== tag.title,
                  })}
                  onMouseEnter={() => setHoverIndex(i)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={() => handleFilterClick(tag.title)}
                >
                  {activeTag === tag.title || hoverIndex === i ? (
                    <Arrow className="w-6 mr-3" />
                  ) : (
                    <Next className="w-6 mr-3" />
                  )}

                  <p className="self-center">{tag.title}</p>
                </div>
              )
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
          {tagFilter?.map((node, i) => (
            <motion.div
              key={`project-${i}`}
              className="h-[700px] w-full relative flex items-end justify-center border border-light-grey-alt"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <div
                className={cx(
                  "z-10  px-10  pt-16 pb-10  text-center items-start transition-all ease-in-out delay-100 duration-500 flex flex-col",
                  {
                    "text-white": i === activeIndex,
                    "text-dark-grey": i !== activeIndex,
                  }
                )}
              >
                <motion.h1
                  layout="position"
                  className="text-xl pb-4 w-fit mx-auto"
                >
                  {node.title}
                </motion.h1>
                <div
                  className={cx("text-sm  py-6", {
                    "absolute top-0 right-0 opacity-0": i === activeIndex,
                  })}
                >
                  <motion.p
                    className="line-clamp-3	"
                    variants={variants2}
                    initial="nonActive"
                    animate={i === activeIndex ? "active" : "nonActive"}
                  >
                    {node.excerpt}
                  </motion.p>
                </div>

                <Link
                  onMouseEnter={() => setActiveIndex(i)}
                  onMouseLeave={() => setActiveIndex(null)}
                  to={`/${node.slug.current}`}
                  className={cx(
                    "row-start-4 tracking-[.1rem] self-center underline underline-offset-8 decoration-2 transition-all ease-in-out duration-500 inline-block relative z-10 pt-20 px-20  -mt-20 -mx-20  ",
                    {
                      "text-white ": i === activeIndex,
                      "text-dark-green ": i !== activeIndex,
                    }
                  )}
                >
                  READ MORE
                </Link>
              </div>

              <motion.img
                src={node.featuredImage.asset.url}
                className="absolute z-0  object-cover"
                alt=""
                variants={variants}
                initial="nonActive"
                animate={activeIndex === i ? "active" : "nonActive"}
              />
              {activeIndex === i && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.5 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: 0.4, duration: 0.8 }}
                  className="absolute top-0 left-0 w-full h-full bg-black"
                />
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </AnimateSharedLayout>
  );
};

export default FilteredProjectsBlock;
