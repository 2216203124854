import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const PageBanner = loadable(() => import("./modules/pageBanner"));
const IntroBlock = loadable(() => import("./modules/introBlock"));
const TitleBlock = loadable(() => import("./modules/titleBlock"));
const IconFeature = loadable(() => import("./modules/iconFeature"));
const FaqSection = loadable(() => import("./modules/faqSection"));
const TestimonialBlock = loadable(() => import("./modules/testimonialBlock"));
const StaffGrid = loadable(() => import("./modules/staffGrid"));
const AwardsBlock = loadable(() => import("./modules/awardsBlock"));
const TileBlock = loadable(() => import("./modules/tileBlock"));
const ContactForm = loadable(() => import("./modules/contactForm"));
const AddressBlock = loadable(() => import("./modules/addressBlock"));
const ProjectsBlock = loadable(() => import("./modules/projectsBlock"));
const PropertyProfile = loadable(() => import("./modules/propertyProfile"));
const LargeImageBlock = loadable(() => import("./modules/largeImageBlock"));
const BlogIntro = loadable(() => import("./modules/blogIntro"));
const TwoColumnText = loadable(() => import("./modules/twoColumnText"));
const Divider = loadable(() => import("./modules/divider"));
const CurtainSlider = loadable(() => import("./modules/curtainSlider"));
const SocialSlider = loadable(() => import("./modules/socialSlider"));
const EmailBlock = loadable(() => import("./modules/emailBlock"));

const ModuleZone = ({ modules, anim }) => {
  const component = modules.map((item, i) => {
    switch (item._type) {
      case "pageBanner":
        return <PageBanner {...item} anim={anim} key={i} />;
      case "introBlock":
        return <IntroBlock {...item} key={i} />;
      case "titleBlock":
        return <TitleBlock {...item} key={i} />;
      case "iconFeature":
        return <IconFeature {...item} key={i} />;
      case "faqSection":
        return <FaqSection {...item} key={i} />;
      case "testimonialBlock":
        return <TestimonialBlock {...item} key={i} />;
      case "staffGrid":
        return <StaffGrid {...item} key={i} />;
      case "awardsBlock":
        return <AwardsBlock {...item} key={i} />;
      case "tileBlock":
        return <TileBlock {...item} key={i} />;
      case "contactForm":
        return <ContactForm {...item} key={i} />;
      case "addressBlock":
        return <AddressBlock {...item} key={i} />;
      case "projectsBlock":
        return <ProjectsBlock {...item} key={i} />;
      case "propertyProfile":
        return <PropertyProfile {...item} key={i} />;
      case "largeImageBlock":
        return <LargeImageBlock {...item} key={i} />;
      case "blogIntro":
        return <BlogIntro {...item} key={i} />;
      case "twoColumnText":
        return <TwoColumnText {...item} key={i} />;
      case "divider":
        return <Divider {...item} key={i} />;
      case "curtainSlider":
        return <CurtainSlider {...item} key={i} />;
      case "socialSlider":
        return <SocialSlider {...item} key={i} />;
      case "emailBlock":
        return <EmailBlock {...item} key={i} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
