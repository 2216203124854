import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useCaseStudyQuery = () => {
  const { allSanityCaseStudy } = useStaticQuery(graphql`
    query {
      allSanityCaseStudy(
        sort: { fields: orderRank, order: ASC } # filter: { _id: { regex: "/^((?!drafts).)*$/" } }
      ) {
        nodes {
          title
          slug {
            current
          }
          excerpt
          tag {
            title
          }
          featuredImage {
            # ...ImageWithPreview
            asset {
              url
            }
          }
        }
      }
    }
  `);
  return allSanityCaseStudy || {};
};
